<template :key="key">
  <div class="frame-container">
    <iframe
      id="frm"
      width="100%"
      height="100%"
      :src="src"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
    
    <!-- {{ src }} -->

    <el-slider
      @change="updateUrl"
      v-model="time"
      range
      show-stops
      :min="minTime"
      :max="maxTime"
      :show-tooltip="true"
    >
    </el-slider>
    <br />
    <el-button-group>
      <el-button @click="goSearch" type="primary" :icon="ArrowLeft"
        >Search again</el-button
      >
      <el-button @click="share" type="primary" :icon="Share"
        >WhatsApp</el-button
      >
    </el-button-group>
  </div>
</template>

<script setup >
import { Share, ArrowLeft } from "@element-plus/icons-vue";
</script>

<script>
export default {
  name: "TrimVideo",
  props: {
    msg: String,
  },
  data() {
    return {
      key: 0,
      maxTime: 0,
      minTime: 0,
      time: [],
      video: "",
      url: "",
      src: "",
    };
  },
  created() {
    this.setVideoUrl();
    this.SetTimeToReload();
    this.url = encodeURIComponent(window.location.href);
  },
  methods: {
    goSearch() {
      this.$router.push("/search");
    },
    share() {
      window.open(`https://api.whatsapp.com/send?&text=${this.url}`, "_blank");
    },
    updateUrl() {
      this.src = `https://www.youtube.com/embed/${this.video}?start=${this.time[0]}&end=${this.time[1]}`;

      let url = window.location.href.split("?")[0];
      url = url + `?v=${this.video}&start=${this.time[0]}&end=${this.time[1]}`;
      this.url = url; // to share with whatsApp
      window.location.replace(url);
    },
    setVideoUrl() {
      const queryParams = this.$route.query;

      this.video = queryParams.v;
      this.time[0] = parseInt(queryParams.start);
      this.time[1] = parseInt(queryParams.end);

      // set slider max min time
      let startTime = this.time[0];
      let endTime = this.time[1];

      this.minTime = startTime - 5;
      this.maxTime = endTime + 5;

      if (startTime <= 5) {
        this.minTime = 0;
      }

      this.src = `https://www.youtube.com/embed/${this.video}?start=${this.time[0]}&end=${this.time[1]}`;
      console.log("load", this.src);
    },
    SetTimeToReload() {
      focus();
      const listener = window.addEventListener("blur", () => {
        if (document.activeElement === document.querySelector("iframe")) {
          setTimeout(function () {
            window.location.reload();
          }, this.reloadTime);
        }
        window.removeEventListener("blur", listener);
      });
    },
  },
  computed: {
    reloadTime() {
      return (this.time[1] - this.time[0] + 1.5) * 1000;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.frame-container {
  height: 80vh;
}
</style>
