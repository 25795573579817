import { createRouter, createWebHashHistory /*createWebHistory*/ } from 'vue-router'
import ShareVideo from '../views/ShareVideo.vue'
//import SearchVideo from '../views/SearchVideo.vue'

const routes = [
  {
    path: '/',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: ShareVideo
  },
  {
    path: '/search',
    name: 'search-video',
    //component: SearchVideo
    component: () => import('../views/SearchVideo.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
